import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  iconsWrapper: {
    height: 120
  },
  icons: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: theme.palette.warning.main,
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1.5)
    },
    '&:hover': {
      // backgroundColor: theme.palette.warning.dark,
    }
  },
  facebook: {
    // backgroundColor: '#fff',
    // color: '#3B5998'
    // borderRadius: 100,
  },
  youtube: {
    // backgroundColor: '#55ACEE',
    // color: '#FFFFFF'
    // borderRadius: 100,
  },
  instagram: {
    // backgroundColor: '#E3569A',
    // color: '#FFFFFF'
    // borderRadius: 100,
  }
}));

// eslint-disable-next-line filenames/match-exported
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {useStyles} from './styled';

import Icon from 'src/components/CustomIcons/Icon';

const items = [
  {
    width: 32,
    height: 32,
    src: '/images/imagesNext/social/facebook.svg',
    link: 'https://www.facebook.com/probemasgold'
  },
  {
    width: 32,
    height: 32,
    src: '/images/imagesNext/social/youtube.svg',
    link: 'https://www.youtube.com/channel/UCeM6e_c-GolkhtMFFwDnavQ',
    label: 'youtube'
  },
  {
    width: 32,
    height: 32,
    src: '/images/imagesNext/social/discord.svg',
    link: 'https://discord.gg/probemas',
    label: 'discord'
  },
  {
    width: 32,
    height: 32,
    src: '/images/imagesNext/social/tiktok.svg',
    link: 'https://www.tiktok.com/@probemas.com',
    label: 'tiktok'
  }
];

const SocialIcons = ({className}) => {
  const classes = useStyles();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const coeff = isDownSm ? 1.15 : 1;
  return (
    <div className={clsx(classes.icons, className)}>
      {items.map(item => (
        <a
          key={item.src}
          href={item.link}
          aria-label={item.label}
          className={classes.icon}
          rel='noreferrer'
          target='_blank'
        >
          <Icon
            src={item.src}
            width={item.width / coeff}
            height={item.height / coeff}
          />
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;
